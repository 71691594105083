<script>
import FormFieldInput from "@components/form-field-input";
import moment from "moment";

export default {
    name: "NocDetail",
    components: {
        FormFieldInput,
    },
    data() {
        return {
            noc: {},
            showModal: false,
            guidId: null,
            validTill: null,
            nocSlip: ""
        };
    },
    created() {
        this.guidId = this.$route?.params?.guidId;

        this.getData();
    },
    methods: {
        async getData() {
            try {
                const { noc } = await this.$http.getById(
                    "noc/detail-guid",
                    this.guidId
                );
                this.noc = noc;
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        async generatePdf() {
            try {
                const file = await this.$http.rawPost(
                    `noc/get-noc-slip/${this.noc.id}`
                );

                this.nocSlip = file.file
                this.$refs.downloadPdf.href = `${process.env.VUE_APP_HOST}/pdf/${this.nocSlip}.pdf`;
                this.$refs.downloadPdf.click();
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
    },
    computed: {
        isValid() {
            if (this.noc) {
                const validTill = moment(this.noc.issueDate).add(4, "years");
                const currentDate = moment();
                const difference = validTill.diff(currentDate, "days");

                this.validTill = validTill;

                if (difference) {
                    return true;
                }
                return false;
            }
            return false;
        },
        nocFileName() {
            return `${process.env.VUE_APP_HOST}/pdf/${this.nocSlip}.pdf`;
        },
    },
};
</script>

<template lang="pug">
.container.px-4.py-8.text-center.mx-auto
    h2.text-center.text-4xl.mb-8.res-h1.pb-4.border NO OBJECTION CERTIFICATE
    span.text-center
        p.text-green-600.text-3xl(v-if="isValid") Valid till {{ $filters.dateFormat(noc.expiryDate, 'DD MMM, YYYY') }}
        p.text-red-600.text-3xl(v-else) Noc has been expired
    .grid.grid-rows-2.grid-flow-col.gap-4.res-col
        .row-span-3.ml-auto.res-row
            .flex.m-8
                .text-2xl Name: {{ noc.name ? $filters.humanize(noc.name) : 'Na' }}
            .flex.m-8
                .text-2xl Phone: {{ noc.phone }}
            .flex.m-8
                .text-2xl Email: {{ noc.email }}
            .flex.m-8
                .text-2xl State: {{ noc.state }}
            .flex.m-8
                .text-2xl NOC Number: {{ noc.nocNumber }}
        .row-span-3.mr-auto.res-row
            .flex.m-8
                .text-2xl Registeration Type: {{ noc.type ? $filters.humanize(noc.type) : 'Na' }}
            .flex.m-8
                .text-2xl Chassis Number: {{ noc.chassisNumber }}
            .flex.m-8
                .text-2xl Motor Number: {{ noc.motorNumber }}
            .flex.m-8
                .text-2xl Vehical Type: {{ noc.vehicleType ? $filters.humanize(noc.vehicleType) : 'Na' }}
            .flex.m-8
                .text-2xl Issued Date: {{  $filters.dateFormat(noc.issueDate, "DD MMM, YYYY") || 'Na'  }}
    .mx-auto.res-row.text-center
        .flex.m-8.justify-center(v-if="noc.createdBy")
            .text-2xl Created By: {{ $filters.humanize(noc.Dealer.shopName) || 'Na' }}
    hr
    p.lead.mt-4
        button.bg-green-500.px-7.py-4.rounded-lg(
            class="hover:bg-yellow-600",
            @click="generatePdf"
        ) Download Certificate
    a.display-hide(
        download,
        target="_blank",
        ref="downloadPdf"
    )
</template>
